<template>
  <div class="reward container">
    <div class="title">我的团队</div>
    <div class="content">

      <div class="invite">
        <div class="link">
          <div class="sub-title">{{ $t('app.mine.subTitle1') }}</div>
          <div style="margin-top: 10px;font-size: 12px">{{ updateaddress2(inviteUrl)  }} <i @click="handleCopyClick"></i></div>
        </div>
        <div style="height: 20px"></div>
        <div class="tabs">
          <h4 :class="{active:activeTab == 0}" @click="activeTab = 0">{{$t('app.invest.DirectSubordinate')}}</h4>
          <h4 :class="{active:activeTab == 1}" @click="activeTab =1">{{ $t('news.level')}}</h4>

        </div>
        <div class="list" v-if="activeTab == 0">
          <header>
            <div>
              <div>{{$t('app.invest.Address')}}</div>
              <span>{{ $t('news.levelamount')}}</span>
              <span>{{$t('app.invest.CommunityPerformance')}}</span>
              <span>{{ $t('news.level')}}</span>
            </div>
          </header>
          <main>
            <div v-for="item in subList" :key="item.address">
              <div>{{updateaddress(item.user) }}</div>
              <span>{{fromData(item.amount)}}</span>
              <span>{{fromData(item.kpi)}}</span>
              <span>{{item.level}}</span>
            </div>
          </main>
          <div class="empty" v-if="subList.length <= 0">
            <div class="empty-bg"></div>
            <div class="tips">{{ $t('com.empty') }}</div>
          </div>
        </div>
        <div class="list" v-else-if="activeTab == 1">
          <header>
            <div>
              <div>地址</div>
              <span>{{ $t('news.nowlevel')}}</span>
              <span>业绩</span>
              <span>大{{ $t('news.area')}}</span>
              <span>小{{ $t('news.area')}}</span>
            </div>
          </header>
          <main>
            <div >
              <div>{{updateaddress(this.address)}}</div>
              <span>{{myData.level}}</span>
              <span>{{Number(fromData(myData.kpi)).toFixed(0)}}</span>
              <span>{{top1}}</span>
              <span>{{top2}}</span>
            </div>
          </main>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers'
// import ERC20 from '../../contracts/ERC20.json'
// import Fare from '../../contracts/Fare.json'
// import { get, post } from '../../utlis/axios'
import { initSigner } from '../../utlis/index'
import BindJson from "@/contracts/NewBind.json";
import MinerJson from "@/contracts/NewMiner.json";
export default {
  data () {
    return {
      inviteUrl:'',
      contracts: {},
      address: '',
      subList:[],
      activeTab:0,
      myData:{},
      top1:0,
      top2:0,
    }
  },
  async mounted() {
    let {address, provider, signer} = await initSigner()
    if (!address || !provider || !signer) return;
    this.address = address;
    this.inviteUrl = "https://"+location.host+"/fomo?referee="+address
    this.contracts.Bind = new ethers.Contract(this.$common.FOMO.Bind, BindJson, provider).connect(signer);
    this.contracts.MinerHelper = new ethers.Contract(this.$common.FOMO.MinerHelper, MinerJson, provider).connect(signer);

    this.getData();
  },
  computed:{

  },
  beforeDestroy () {
    clearInterval(this.initTimer)
  },
  methods: {
    fromData(value)
    {
      return ethers.utils.formatUnits(value,18);
    },
    getData()
    {
      this.contracts.MinerHelper.userMap(this.address).then(res => {
          console.log(res);
          this.myData = res;

          console.log(Number(this.myData.subNum));

          let  sumNum = 0
          if (this.address=='0xfd3A522b2e83Ef2FBacD8e61f262D84177D9F4b2')
          {
            sumNum = Number(this.myData.subNum) - 1
          }else
          {
            sumNum = Number(this.myData.subNum)
          }
         this.contracts.MinerHelper.getSubordinatesList(this.address,0,sumNum).then(res => {
          console.log(res);
          this.subList = res;
          this.top1 = 0;
          this.top2 = 0;
          for (let i =0;i<res.length;i++)
          {
            let userDetail = res[i];
            let kpi = Number(ethers.utils.formatUnits(userDetail.kpi,18));
            console.log(kpi);
            if (kpi>this.top1)
            {
              this.top1 = kpi;
              this.top1 = this.top1.toFixed(0)
            }
            this.top2 = this.top2 + kpi;

          }
          this.top2 =  this.top2 - this.top1;
          this.top2 = this.top2.toFixed(0)
        })
     })





    },
    updateaddress(address) {
      let newaddress = "";
      if (address) {
        newaddress =
            address.substring(0, 4) +
            "..." +
            address.substring(address.length - 4);
      }
      return newaddress;
    },

    updateaddress2(address) {
      let newaddress = "";
      if (address) {
        newaddress =
            address.substring(0, 33) +
            "..." +
            address.substring(address.length - 6);
      }
      return newaddress;
    },

    // 复制邀请链接
    handleCopyClick() {

      let input = document.createElement("input");
      input.value = this.inviteUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success(this.$t('app.cooperation.copyCG'))
    },


  },
  components: {}
}
</script>

<style lang="scss" scoped>
.seepoint{
  display: flex;
  flex-direction: column;
  >div{
    width: 40%;
    margin: 0 auto;
  }
  >section{
    display: grid;
    margin-top: .5rem;
    grid-template-columns: 40% 40%;
    justify-content: space-around;
    >div{
      width: 100%;
    }
  }
}
.reward {
  padding: 1.4rem 0.44rem 0.4rem;
  .empty {
    font-size: 0.28rem;
    padding: 0.4rem 0;
    .empty-bg {
      width: 2.9rem;
      height: 1.87rem;
      margin: 0 auto;
      background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
    }
    .tips {
      margin-top: 0.2rem;
      text-align: center;
    }
  }
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
  }
  .content{
    .header{
      display: flex;
      flex-direction: column;
      background: url(../../assets/img/coin-bg.png) no-repeat;
      background-size: 100% 100%;
      padding: .6rem .6rem;
      >section{
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: .26rem;
        margin-bottom: .3rem;
      }
      >div{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        font-size: .22rem;
        >div{
          margin-bottom: .3rem;
          width: 40%;
        }
      }
      >div > div > span,> section > span{
        margin-top: .25rem;
        display: flex;
        align-items: center;
        font-size: .3rem;
        color: #3E9DD2;
        line-height: .32rem;
        >img{
          width: .4rem;
          margin-left: .1rem;
        }
      }
      > section > span{
        justify-content: center;
      }
      button{
        height: .96rem;
        background: rgba(178,133,237,0.8);
        border: 1px solid #45BBF3;
        font-size: .32rem;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 2px 1px rgba(0,0,0,0.54);
      }

    }
    .invite{
      .link {
        font-size: 0.3rem;
        div {
          font-size: 0.3rem;
          font-weight:800;
          &:last-child {
            display: flex;
            align-items: center;
            color: #45bbf3;
            i {
              width: 0.38rem;
              height: 0.38rem;
              margin-left: 0.25rem;
              cursor: pointer;
              background: url("../../assets/img/web-copy.png") left top / 100%
              100% no-repeat;
            }
          }
        }
      }
      margin-top: .3rem;
      .myID{
        margin:.3rem 0;
        display: flex;
        justify-content: space-between;
        i {
          width: 0.38rem;
          height: 0.38rem;
          margin-left: 0.25rem;
          cursor: pointer;
          background: url("../../assets/img/web-copy.png") left top / 100%
          100% no-repeat;
        }
      }
      .tabs{
        display: flex;
        >h4{
          margin-right: .3rem;
          color: #c4c4c4;
        }
        .active{
          color: #FFFFFF;
          font-size: 1.05em;
        }
      }
      .list{
        display: flex;
        flex-direction: column;
        margin-top: .2rem;
        header,main{
          display: flex;
          flex-direction: column;
          >div{
            display: flex;
            line-height:.65rem;
            >div,>span{
              text-align: center;
              width: 50%;
            }
          }
        }
        header{
          font-size: .28rem;
        }
        main{
          font-size: .26rem;
          max-height:200px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 100%;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            background: #007aaa59;
          }
          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            background: #007aaa59;
          }
          &::-webkit-scrollbar-corner{
            background: #007aaa59;
          }
          >div{
            >span{
              color: #3E9DD2;
            }
          }
        }
      }
    }
    a{
      text-align: center;
      font-size: .24rem;
      font-weight: 400;
      color: #45BBF3;
    }
  }
}
</style>
